<template>
    <section>
        <modal-lateral ref="modalDetalleRuta" titulo="No. 584512">
            <template slot="title-edit">
                <div class="col px-0 d-middle">
                    <p class="col-auto px-0 text-general f-17 text-white f-500">
                        No. {{ idPedido }}
                    </p>
                    <div v-show="pedido.estado === 3 && $can('botones_abastecimiento_finalizar_pedido')" class="btn-general px-3 f-14 ml-auto" @click="finalizarPedido">
                        Finalizar
                    </div>
                    <el-tooltip class="ml-auto" placement="bottom" content="Desvincular" effect="light">
                        <div class="btn-action border cr-pointer ml-3" @click="desvincularPedido">
                            <i class="icon-playlist-remove f-22" />
                        </div>
                    </el-tooltip>
                </div>
            </template>
            <div class="row mx-0 border-bottom">
                <div
                v-for="(t, idx) in tabs"
                :key="idx"
                class="col text-center px-2 pb-1 cr-pointer f-15"
                :class="tabActiva == t.component_name ? 'text-general border-b-black' : 'text-general2'"
                @click="tabActiva = t.component_name"
                >
                    {{ t.titulo }}
                </div>
            </div>
            <div class="custom-scroll overflow-auto" style="height:calc(100vh - 93px)">
                <evidencia v-show="tabActiva == 'evidencia'" :envios="envios" />
                <ubicacion v-show="tabActiva == 'ubicacion'" />
                <productos v-show="tabActiva == 'productos'" :productos="productos" />
                <detalle v-show="tabActiva == 'detalle'" :pedido="pedido" :resumen="resumen" />
            </div>
        </modal-lateral>
        <!-- Partials -->
        <modal ref="modalDesvincularPedido" titulo="Desvincular pedido" no-aceptar adicional="Desvincular" @adicional="desvincular">
            <div class="row mx-0 py-4">
                <p class="col-12 text-general f-15 text-center">
                    ¿Desea desvincular este pedido de las <br /> entregas del operador logístico?
                </p>
            </div>
        </modal>
        <modal-finalizar-pedido ref="modalFinalizarPedido" @accion-finalizar="accionFinalizar" @credito="modalCredito" />
        <modalOtorgarCredito ref="OtorgarCredito" />
    </section>
</template>

<script>
import Pedido from "~/services/pedidos/pedidos-routing";
import PedidosAdmin from '~/services/pedidos/pedidos-admin'
export default {
    components: {
        evidencia: () => import('../components/evidencias'),
        ubicacion: () => import('../components/ubicacion'),
        productos: () => import('../components/productos'),
        detalle: () => import('../components/detalle'),
        modalFinalizarPedido: () => import('~/pages/almacen/pedidos/partials/modalFinalizarPedido.vue'),
        modalOtorgarCredito: () => import('~/pages/almacen/pedidos/partials/modalOtorgarCredito.vue')
    },
    data(){
        return {
            tabs: [
                { titulo: 'Evidencias', component_name: 'evidencia' },
                { titulo: 'Ubicación', component_name: 'ubicacion' },
                { titulo: 'Productos', component_name: 'productos' },
                { titulo: 'Detalle', component_name: 'detalle' }
            ],
            tabActiva: 'detalle',
            idPedido:null,
            productos:[],
            envios:[],
            pedido:{},
            resumen:{}
        }
    },
    methods: {
        async toggle(idPedido){
            this.tabActiva = 'detalle'
            this.idPedido = idPedido
            await this.loadInformacion()
            this.$refs.modalDetalleRuta.toggle();
        },
        async loadInformacion(){
            try {

                const {data} = await Pedido.getInfoRouting(this.idPedido)
                this.pedido = data.pedido
                this.productos = data.productos
                this.resumen = data.resumen
                this.envios = data.evidencias

                if(this.pedido.estado === 3){
                    this.$store.commit('pedidos/pedidos_admin/set_id_pedido',this.idPedido)
                    this.$store.commit('pedidos/pedidos_admin/set_pedido',data.pedido)
                    this.$store.commit('pedidos/pedidos_admin/set_resumenes',data)
                }


            } catch (error){
                this.error_catch(error)
            }
        },
        desvincularPedido(){
            this.$refs.modalDetalleRuta.toggle();
            this.$refs.modalDesvincularPedido.toggle();
        },
        finalizarPedido(){
            this.$refs.modalDetalleRuta.toggle();
            this.$refs.modalFinalizarPedido.toggle();
        },
        accionFinalizar(){
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido',null)
            this.$store.commit('pedidos/pedidos_admin/set_pedido',{})
            this.$emit('update')
        },
        modalCredito(){
            this.$refs.OtorgarCredito.toggle()
        },
        async desvincular(){
            try {
                const form = { id_pedido: this.idPedido, };
                const {data} = await PedidosAdmin.desvincularOperadorLogistico(form)
                this.notificacion('Mensaje', 'Se ha desvinculado el pedido de la operación logistica', 'success')
                this.$emit('update')
                this.$refs.modalDesvincularPedido.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
